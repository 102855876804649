import { ChainId, Token } from '@hoswap/sdk'

export const USDT: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDT',
    'Binance-Peg BSC-USD',
  ),
}

export const HOS: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xa0949F33FA921ca2571d469A1a4b08649838D34C',
    18,
    'HOS',
    'Binance-Peg BSC-HOS',
  ),
}

export const HO: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x41515885251e724233c6cA94530d6dcf3A20dEc7',
    18,
    'HO',
    'Binance-Peg BSC-HO',
  ),
}

export const WBNB: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x7f95b241e27fc6Ac89390Aa12eF40706F38254F6', 18, 'WBNB', 'WBNB'),
}

const tokens = {
  bnb: {
    symbol: 'BNB',
  },
  wbnb: {
    symbol: 'WBNB',
    address: {
      56: '0x7f95b241e27fc6Ac89390Aa12eF40706F38254F6',
    },
    decimals: 18,
  },
  pancakeWBNB: {
    symbol: 'Wrapped BNB Pancake',
    address: {
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    decimals: 18,
  },
  cake: {
    symbol: 'CAKE',
    address: {
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    },
    decimals: 18,
  },
  ho: {
    symbol: 'HO',
    address: {
      56: '0x41515885251e724233c6cA94530d6dcf3A20dEc7',
    },
    decimals: 18,
  },
  usdt: {
    symbol: 'USDT',
    address: {
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    decimals: 18,
  },
  busd: {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    },
    decimals: 18,
  },
  far: {
    symbol: 'FAR',
    address: {
      56: '0xa0eCAf5Edf5df69edC7768DA897dCeA7D0c8e526',
    },
    decimals: 18,
  },
  zeed: {
    symbol: 'ZEED',
    address: {
      56: '0xB2F53069E1555793481aAfe639F8E274f4Ec8435',
    },
    decimals: 18,
  },
  fil: {
    symbol: 'FIL',
    address: {
      56: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
    },
    decimals: 18,
  },
  eth: {
    symbol: 'ETH',
    address: {
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    },
    decimals: 18,
  },
  btc: {
    symbol: 'BTCB',
    address: {
      56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'
    },
    decimals: 18,
  },
  usdc: {
    symbol: 'USDC',
    address: {
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    },
    decimals: 18,
  },
  hos: {
    symbol: 'HOS',
    address: {
      56: '0xa0949F33FA921ca2571d469A1a4b08649838D34C',
    },
    decimals: 18,
  },
  hor: {
    symbol: 'HOR',
    address: {
      56: '0xc399DebeAa5a580D3AaBdaf1164Ed8f056D09fc8',
    },
    decimals: 18,
  },
  rrb: {
    symbol: 'RRB',
    address: {
      56: '0xa8d22026119bC8A33941853614148Fda61971d4D',
    },
    decimals: 8,
  },
  hbg: {
    symbol: 'HBG',
    address: {
      56: '0xe64Fd924909D2a441a85d82b633A4CC3c86E0f20',
    },
    decimals: 18,
  },
  nar: {
    symbol: 'NAR',
    address: {
      56: '0x777ACbC3B6E9b22019980C5F41f9E04Cd51a4a4B',
    },
    decimals: 18,
  },
  mos: {
    symbol: 'MOS',
    address: {
      56: '0x51760576279bf7ee35631d33ede4757de19d985d',
    },
    decimals: 18,
  }
}

export default tokens
