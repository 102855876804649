import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    // 质押币种
    stakingToken: tokens.wbnb,
    // 产出币种
    earningToken: tokens.usdt,
    contractAddress: {
      56: '0x3605334F187fA97927F998dBf09472E16Fdd6aA5',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
  },
  // ,
  // {
  //   sousId: 214,
  //   stakingToken: tokens.cake,
  //   earningToken: tokens.naos,
  //   contractAddress: {
  //     97: '',
  //     56: '0xb38b78529bCc895dA16CE2978D6cD6C56e8CfFC3',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   sortOrder: 999,
  //   tokenPerBlock: '0.1736',
  // }
]

export default pools
