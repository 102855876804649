import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'HO/USDT LPToken',
    lpAddresses: {
      56: '0x4374f82d2d6f65815c0783850fc36817db16bbfb',
    },
    token: tokens.ho,
    quoteToken: tokens.usdt,
    earningToken: tokens.hos,
  },
  {
    pid: 1,
    lpSymbol: 'HO/HOS LPToken',
    lpAddresses: {
      56: '0x11576B79809E6F6F02FD3E2726Faa1a69b597d6A',
    },
    token: tokens.ho,
    quoteToken: tokens.hos,
    earningToken: tokens.hos,
  },
  {
    pid: 2,
    lpSymbol: 'HOS/BNB LPToken',
    lpAddresses: {
      56: '0x1d5a3F7125E7c753d3EEb298F265451a323654eF',
    },
    token: tokens.hos,
    quoteToken: tokens.wbnb,
    earningToken: tokens.hos,
  },
  {
    pid: 3,
    lpSymbol: 'HO/USDT LPToken',
    lpAddresses: {
      56: '0x4374f82d2d6f65815c0783850fc36817db16bbfb',
    },
    token: tokens.ho,
    quoteToken: tokens.usdt,
    earningToken: tokens.hos,
  },
  {
    pid: 4,
    lpSymbol: 'HO/HOS LPToken',
    lpAddresses: {
      56: '0x11576B79809E6F6F02FD3E2726Faa1a69b597d6A',
    },
    token: tokens.ho,
    quoteToken: tokens.hos,
    earningToken: tokens.hos,
  },
  {
    pid: 5,
    lpSymbol: 'HOS/BNB LPToken',
    lpAddresses: {
      56: '0x1d5a3F7125E7c753d3EEb298F265451a323654eF',
    },
    token: tokens.hos,
    quoteToken: tokens.wbnb,
    earningToken: tokens.hos,
  },
  {
    pid: 6,
    lpSymbol: 'HO/RRB LPToken',
    lpAddresses: {
      56: '0x5b378fe201e8f3ef28de62d73eecfb4f4067625a',
    },
    token: tokens.rrb,
    quoteToken: tokens.ho,
    earningToken: tokens.rrb,
  },
  {
    pid: 7,
    lpSymbol: 'ZEED/USDT LPToken',
    lpAddresses: {
      56: '0x246423e4782B2bf0e8A439E88f8A4c7B28201F05',
    },
    token: tokens.zeed,
    quoteToken: tokens.usdt,
    earningToken: tokens.zeed,
  },
  {
    pid: 8,
    lpSymbol: 'ZEED/HO LPToken',
    lpAddresses: {
      56: '0x6fa91E90edd92EA251a34BC17dd066df470c7F57',
    },
    token: tokens.zeed,
    quoteToken: tokens.ho,
    earningToken: tokens.ho,
  },
  {
    pid: 9,
    lpSymbol: 'ZEED/HOS LPToken',
    lpAddresses: {
      56: '0x3286C5378C2d2f34830A2B0F620Ad36d85648eb2',
    },
    token: tokens.zeed,
    quoteToken: tokens.hos,
    earningToken: tokens.hos,
  },
  {
    pid: 10,
    lpSymbol: 'HO/USDT LPToken',
    lpAddresses: {
      56: '0x4374f82D2D6F65815C0783850fC36817Db16Bbfb',
    },
    token: tokens.ho,
    quoteToken: tokens.usdt,
    earningToken: tokens.hbg,
  },
  {
    pid: 11,
    lpSymbol: 'HO/HOS LPToken',
    lpAddresses: {
      56: '0x11576B79809E6F6F02FD3E2726Faa1a69b597d6A',
    },
    token: tokens.ho,
    quoteToken: tokens.hos,
    earningToken: tokens.hbg,
  },
  {
    pid: 12,
    lpSymbol: 'ZEED/USDT LPToken',
    lpAddresses: {
      56: '0x246423e4782B2bf0e8A439E88f8A4c7B28201F05',
    },
    token: tokens.zeed,
    quoteToken: tokens.usdt,
    earningToken: tokens.zeed,
  },
  {
    pid: 13,
    lpSymbol: 'NAR/HO LPToken',
    lpAddresses: {
      56: '0x43982C92F79c91Be8323f3b852Fb715a132638a3',
    },
    token: tokens.nar,
    quoteToken: tokens.ho,
    earningToken: tokens.nar,
  },
  {
    pid: 16,
    lpSymbol: 'HO/MOS LPToken',
    lpAddresses: {
      56: '0x8ffd4463a65AdFF44A7aA77f227457fb0C91796B',
    },
    token: tokens.mos,
    quoteToken: tokens.ho,
    earningToken: tokens.hbg,
  },
  {
    pid: 14,
    lpSymbol: 'HO/FIL LPToken',
    lpAddresses: {
      56: '0xCC93f5ce7025302e82E1A3Cd96BC0A272c9483dd',
    },
    token: tokens.fil,
    quoteToken: tokens.ho,
    earningToken: tokens.hbg,
  },
  {
    pid: 15,
    lpSymbol: 'HOS/FIL LPToken',
    lpAddresses: {
      56: '0xC7eA7CB3A576454bF32EBBbb005e1F5FEc5e28A7',
    },
    token: tokens.fil,
    quoteToken: tokens.hos,
    earningToken: tokens.hbg,
  },
  {
    pid: 18,
    lpSymbol: 'HO/RRB LPToken',
    lpAddresses: {
      56: '0x5b378fe201e8f3ef28de62d73eecfb4f4067625a',
    },
    token: tokens.rrb,
    quoteToken: tokens.ho,
    earningToken: tokens.hbg,
  },
]

export default farms
