import BigNumber from 'bignumber.js'
import masterchefABI from 'config/abi/masterchef_pancakeswap.json'
import erc20 from 'config/abi/erc20.json'
import { getAddress, getMasterChefAddress, getPancakeMasterChefAddress } from 'utils/addressHelpers'
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import multicallBSC from 'utils/multicall_bsc'
import multicall from 'utils/multicall'
import { Farm } from '../types'
import PublicFarmData from './farmData'

const fetchFarmFromPancakeSwap = async (farm: Farm): Promise<PublicFarmData> => {
  const { pid, lpAddresses, token, quoteToken } = farm
  const bscChainID = 56
  const bscCalls = [
    // Balance of token in the LP contract
    {
      address: token.address[bscChainID],
      name: 'balanceOf',
      params: [lpAddresses[bscChainID]],
    },
    // Balance of quote token on LP contract
    {
      address: quoteToken.address[bscChainID],
      name: 'balanceOf',
      params: [lpAddresses[bscChainID]],
    },
    // Total supply of LP tokens
    {
      address: lpAddresses[bscChainID],
      name: 'totalSupply',
    },
    // Token decimals
    {
      address: token.address[bscChainID],
      name: 'decimals',
    },
    // Quote token decimals
    {
      address: quoteToken.address[bscChainID],
      name: 'decimals',
    },
  ]

  const [tokenBalanceLP, quoteTokenBalanceLP, lpTotalSupply, tokenDecimals, quoteTokenDecimals] = await multicallBSC(
    erc20,
    bscCalls,
  )

  const [lpTokenBalanceMC] = await multicall(erc20, [
    // Balance of LP tokens in the master chef contract
    {
      address: getAddress(lpAddresses),
      name: 'balanceOf',
      params: [getPancakeMasterChefAddress()],
    },
  ])

  // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
  const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

  // Raw amount of token in the LP, including those not staked
  const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
  const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))

  // Amount of token in the LP that are staked in the MC (i.e amount of token * lp ratio)
  const tokenAmountMc = tokenAmountTotal.times(lpTokenRatio)
  const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

  // Total staked in LP, in quote token value
  const lpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2))

  // Only make masterchef calls if farm has pid
  // const [info] = await multicall(masterchefABI, [
  //   {
  //     address: getPancakeMasterChefAddress(),
  //     name: 'poolInfo',
  //     params: [pid],
  //   },
  // ])

  const ret = {
    tokenAmountMc: tokenAmountMc.toJSON(),
    quoteTokenAmountMc: quoteTokenAmountMc.toJSON(),
    tokenAmountTotal: tokenAmountTotal.toJSON(),
    quoteTokenAmountTotal: quoteTokenAmountTotal.toJSON(),
    lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
    lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
    tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
    // lastWithdrawBlockNumber: (info.lastWithdrawBlock as BigNumber).toString(),
    // rewardEndBlockNumber: (info.endBlockNumber as BigNumber).toString(),
    // rewardBeginBlockNumber: info.beginBlockNumber.toJSON(),
    // rewardBeginBlockNumber: new BigNumber(0).toJSON(),
    // rewardPerBlock: (info.rewardPerBlock as BigNumber).toString(),
  }

  return ret
}

export default fetchFarmFromPancakeSwap
