export default {
  pancakeSwapMasterChef: {
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  masterChef: {
    56: '0xcB2B26B8ae08c846b253f50d57F15dB0A41B96e1',
  },
  sousChef: {
    56: '0x000000000000000000000000000000000000dead',
  },
  lotteryV2: {
    56: '0x000000000000000000000000000000000000dead',
  },
  multiCall: {
    56: '0x03aA8ACE25Ab4A8d7810C5210FF62F55a07d1a71',
  },
  pancakeProfile: {
    56: '0x000000000000000000000000000000000000dead',
  },
  pancakeRabbits: {
    56: '0x000000000000000000000000000000000000dead',
  },
  bunnyFactory: {
    56: '0x000000000000000000000000000000000000dead',
  },
  claimRefund: {
    56: '0x000000000000000000000000000000000000dead',
  },
  pointCenterIfo: {
    56: '0x000000000000000000000000000000000000dead',
  },
  bunnySpecial: {
    56: '0x000000000000000000000000000000000000dead',
  },
  tradingCompetition: {
    56: '0x000000000000000000000000000000000000dead',
  },
  easterNft: {
    56: '0x000000000000000000000000000000000000dead',
  },
  cakeVault: {
    56: '0x000000000000000000000000000000000000dead',
  },
  predictions: {
    56: '0x000000000000000000000000000000000000dead',
  },
  chainlinkOracle: {
    56: '0x000000000000000000000000000000000000dead',
  },
  bunnySpecialCakeVault: {
    56: '0x000000000000000000000000000000000000dead',
  },
  bunnySpecialPrediction: {
    56: '0x000000000000000000000000000000000000dead',
  },
  bunnySpecialLottery: {
    56: '0x000000000000000000000000000000000000dead',
  },
  farmAuction: {
    56: '0x000000000000000000000000000000000000dead',
  },
}
