import BigNumber from 'bignumber.js'
import { BIG_ONE, BIG_ZERO } from 'utils/bigNumber'
import { filterFarmsByQuoteToken } from 'utils/farmsPriceHelpers'
import { Farm } from 'state/types'
import { Route, Fetcher, Token } from '@hoswap/sdk'
import tokens from 'config/constants/tokens'
import fetchFarmFromPancakeSwap from './fetchPublicFarmDataPancake'

// const getFarmFromTokenSymbol = (farms: Farm[], tokenSymbol: string, preferredQuoteTokens?: string[]): Farm => {
//   const farmsWithTokenSymbol = farms.filter((farm) => farm.token.symbol === tokenSymbol)
//   const filteredFarm = filterFarmsByQuoteToken(farmsWithTokenSymbol, preferredQuoteTokens)
//   return filteredFarm
// }

// const getFarmBaseTokenPrice = (farm: Farm, hoBusdFarm: Farm, hoHosFarm: Farm): BigNumber => {

//   switch (farm.pid) {
//     case 0: return new BigNumber(farm.tokenPriceVsQuote);
//     case 1: return new BigNumber(hoBusdFarm.tokenPriceVsQuote);
//     // case 2: return fra
//     default: return BIG_ZERO;
//   }
// }

// const getFarmQuoteTokenPrice = (farm: Farm, hoBusdFarm: Farm, hoHosFarm: Farm): BigNumber => {

//   switch (farm.pid) {
//     case 0: return BIG_ONE;
//   }

//   // if (farm.quoteToken.symbol === 'USDT') {
//   //   return BIG_ONE
//   // }

//   // if (!quoteTokenFarm) {
//   //   return BIG_ZERO
//   // }

//   // if (quoteTokenFarm.quoteToken.symbol === 'USDT') {
//   //   return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
//   // }

//   // return BIG_ZERO
// }

const PancakeBNBBUSDFarm: Farm = {
  pid: 252,
  lpSymbol: 'BUSD-BNB LP',
  lpAddresses: {
    56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
  },
  token: tokens.busd,
  quoteToken: tokens.pancakeWBNB,
  earningToken: tokens.cake
}

const fetchFarmsPrices = async (farms) => {

  // HO Price USD
  const hoUSDFarm = farms.find((farm) => farm.pid === 10);
  const hoBusdPrice = new BigNumber(hoUSDFarm.tokenPriceVsQuote)

  // HOS Price USD
  const hosHOSFram = farms.find((farm) => farm.pid === 11);
  const hosBusdPrice = BIG_ONE.div(new BigNumber(hosHOSFram.tokenPriceVsQuote)).times(hoBusdPrice);

  // BNB Price USD
  const usdBNBFram = await fetchFarmFromPancakeSwap(PancakeBNBBUSDFarm);
  const bnbBusdPrice = BIG_ONE.div(new BigNumber(usdBNBFram.tokenPriceVsQuote));

  // RRB Price USD
  const rrbHOFram = farms.find((farm) => farm.pid === 18);
  const rrbBusdPrice = BIG_ONE.div(new BigNumber(rrbHOFram.tokenPriceVsQuote)).times(hoBusdPrice);

  console.log(`rrbPrice: ${rrbHOFram.tokenPriceVsQuote}`);

  // ZEED Price USD
  const zeedUSDFram = farms.find((farm) => farm.pid === 12);
  const zeedBusdPrice = BIG_ONE.div(new BigNumber(zeedUSDFram.tokenPriceVsQuote))

  /// NAR Price USD
  const narHOFram = farms.find((farm) => farm.pid === 13);
  const narBusdPrice = BIG_ONE.div(new BigNumber(narHOFram.tokenPriceVsQuote)).times(hoBusdPrice);

  /// FIL Price USD
  const filHOFram = farms.find((farm) => farm.pid === 14);
  const filBusdPrice = BIG_ONE.div(new BigNumber(filHOFram.tokenPriceVsQuote)).times(hoBusdPrice);

  /// MOS Price USD
  const mosHOFarm = farms.find((farm) => farm.pid === 16);
  const mosBusdPrice = BIG_ONE.div(new BigNumber(mosHOFarm.tokenPriceVsQuote)).times(hoBusdPrice);

  const farmsWithPrices = farms.map((farm) => {
    let baseTokenPrice;
    let quoteTokenPrice;

    switch (farm.pid) {
      case 10:
      case 3:
      case 0: {
        baseTokenPrice = hoBusdPrice;
        quoteTokenPrice = BIG_ONE;
        break;
      }

      case 11:
      case 4:
      case 1: {
        baseTokenPrice = hoBusdPrice;
        quoteTokenPrice = hosBusdPrice
        break;
      }

      case 5:
      case 2: {
        baseTokenPrice = hosBusdPrice;
        quoteTokenPrice = bnbBusdPrice
        break;
      }

      case 18:
      case 6: {
        baseTokenPrice = rrbBusdPrice;
        quoteTokenPrice = hoBusdPrice
        break;
      }

      case 12:
      case 7: {
        baseTokenPrice = zeedBusdPrice;
        quoteTokenPrice = BIG_ONE;
        break;
      }

      case 8: {
        baseTokenPrice = zeedBusdPrice;
        quoteTokenPrice = hoBusdPrice;
        break;
      }

      case 9: {
        baseTokenPrice = zeedBusdPrice;
        quoteTokenPrice = hosBusdPrice;
        break;
      }

      case 13: {
        baseTokenPrice = narBusdPrice;
        quoteTokenPrice = hoBusdPrice;
        break;
      }

      case 14: {
        baseTokenPrice = filBusdPrice;
        quoteTokenPrice = hoBusdPrice;
        break;
      }

      case 15: {
        baseTokenPrice = filBusdPrice;
        quoteTokenPrice = hosBusdPrice;
        break;
      }

      case 16: {
        baseTokenPrice = mosBusdPrice;
        quoteTokenPrice = hoBusdPrice;
        break;
      }

      default: {
        baseTokenPrice = BIG_ZERO;
        quoteTokenPrice = BIG_ZERO;
      }
    }

    const token = { ...farm.token, busdPrice: baseTokenPrice.toJSON() }
    const quoteToken = { ...farm.quoteToken, busdPrice: quoteTokenPrice.toJSON() }

    return { ...farm, token, quoteToken }
  })

  // console.log(farmsWithPrices)
  return farmsWithPrices
}

export default fetchFarmsPrices
